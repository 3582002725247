export const ROUTES = {
  LOGIN: {
    RELATIVE: "",
    ABSOLUTE: "/",
  },
  UI: {
    RELATIVE: "ui",
    ABSOLUTE: "/ui",
    DASHBOARD: {
      RELATIVE: "dashboard",
      ABSOLUTE: "/ui/dashboard",
    },

    SELF_SERVICE: {
      RELATIVE: "self-service",
      ABSOLUTE: "/ui/self-service/employee-leave-listing",
      ATTENDANCE: {
        RELATIVE: "attendance",
        ABSOLUTE: "/ui/self-service/attendance",
        ATTENDANCE_WFH_REQUEST: {
          RELATIVE: "attendance/wfh-request",
          ABSOLUTE: "/ui/self-service/attendance/wfh-request",
        },
        ATTENDANCE_REGULARIZATION_REQUEST: {
          RELATIVE: "attendance/attendance-regularization-request",
          ABSOLUTE:
            "/ui/self-service/attendance/attendance-regularization-request",
        },
      },
      TIME_SHEET: {
        RELATIVE: "timesheet",
        ABSOLUTE: "/ui/self-service/timesheet",
      },
      LEAVES: {
        RELATIVE: "leaves",
        ABSOLUTE: "/ui/self-service/leaves",
        LEAVES_REQUEST: {
          RELATIVE: "leaves/leave-request",
          ABSOLUTE: "/ui/self-service/leaves/leave-request",
        },
      },
    },

    HUMAN_RESOURCES: {
      RELATIVE: "human-resources",
      ABSOLUTE: "/ui/human-resources/dashboard",
      DASHBOARD: {
        RELATIVE: "dashboard",
        ABSOLUTE: "/ui/human-resources/dashboard",
      },
      SELF_PROFILE: {
        RELATIVE: "self-profile",
        ABSOLUTE: "/ui/human-resources/self-profile",
      },
      BENEFITS_AND_COMPENSATION: {
        RELATIVE: "benefits-and-compensation",
        ABSOLUTE: "/ui/human-resources/benefits-and-compensation",
      },
      REIMBURSMENT_REQUEST_FORM: {
        RELATIVE: "reimbursement-request-form",
        ABSOLUTE: "/ui/human-resources/reimbursement-request-form",
      },
      REIMBURSMENT_REQUEST_LIST: {
        RELATIVE: "reimbursement-request-list",
        ABSOLUTE: "/ui/human-resources/reimbursement-request-list",
      },
      ASSET_LIST: {
        RELATIVE: "asset-list",
        ABSOLUTE: "/ui/human-resources/asset-list",
      },
      ASSET_REQUEST_FORM: {
        RELATIVE: "asset-request-form",
        ABSOLUTE: "/ui/human-resources/asset-request-form",
      },
      DOCUMENTS: {
        RELATIVE: "documents",
        ABSOLUTE: "/ui/human-resources/documents",
      },
      UPLOAD_DOCUMENT_FORM: {
        RELATIVE: "upload-document-form",
        ABSOLUTE: "/ui/human-resources/upload-document-form",
      },
    },

    IT: {
      RELATIVE: "it",
      ABSOLUTE: "/ui/it/help-desk",
      HELP_DESK: {
        RELATIVE: "help-desk",
        ABSOLUTE: "/ui/it/help-desk",
      },
      HELP_DESK_LIST: {
        RELATIVE: "help-desk-list",
        ABSOLUTE: "/ui/it/help-desk-list",
      },
      HELP_DESK_REQUEST: {
        RELATIVE: "help-desk-request",
        ABSOLUTE: "/ui/it/help-desk-request",
      },
      IT_POLICIES_REQUEST: {
        RELATIVE: "it-policies",
        ABSOLUTE: "/ui/it/it-policies",
      },
    },

    PROJECT_MANAGEMENT: {
      RELATIVE: "project-management",
      ABSOLUTE: "/ui/project-management/business-enquiry",
      BUSINESS_ENQUIRY: {
        RELATIVE: "business-enquiry",
        ABSOLUTE: "/ui/project-management/business-enquiry",
        BUSINESS_ENQUIRY_FORM: {
          RELATIVE: "business-enquiry-form",
          ABSOLUTE: "/ui/project-management/business-enquiry-form",
        },
      },
      CONTRACT_REGISTRATION: {
        RELATIVE: "contract-registration",
        ABSOLUTE: "/ui/project-management/contract-registration",
        CONTRACT_REGISTRATION_FORM: {
          RELATIVE: "contract-registration-form",
          ABSOLUTE: "/ui/project-management/contract-registration-form",
        },
        CONTRACT_TASK_ORDER_FORM: {
          RELATIVE: "task-order-form",
          ABSOLUTE:
            "/ui/project-management/contract-registration/task-order-form",
        },
        CONTRACT_INVOICE_RATE_FORM: {
          RELATIVE: "invoice-rate-form",
          ABSOLUTE:
            "/ui/project-management/contract-registration/invoice-rate-form",
        },
        CONTRACT_CONTRACT_POC_FORM: {
          RELATIVE: "contract-poc-form",
          ABSOLUTE:
            "/ui/project-management/contract-registration/contract-poc-form",
        },
      },
      PROJECT_EXECUTION: {
        RELATIVE: "project-execution",
        ABSOLUTE: "/ui/project-management/project-execution",
        PROJECT_EXECUTION_FORM: {
          RELATIVE: "project-execution-form",
          ABSOLUTE: "/ui/project-management/project-execution-form",
        },
      },
    },
  },
};
