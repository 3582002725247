import { redirect } from "react-router-dom";
import { ROUTES } from "./routes.constants";

export const projectManagementRoutes = {
  path: ROUTES.UI.PROJECT_MANAGEMENT.RELATIVE,
  async lazy() {
    let { ProjectManagement } = await import(
      "@/pages/ProjectManagement/ProjectManagement"
    );
    return { Component: ProjectManagement };
  },
  children: [
    {
      path: "",
      loader: () => redirect(ROUTES.UI.PROJECT_MANAGEMENT.ABSOLUTE),
    },
    {
      path: ROUTES.UI.PROJECT_MANAGEMENT.BUSINESS_ENQUIRY.RELATIVE,
      async lazy() {
        let { BusinessEnquiry } = await import(
          "@/pages/ProjectManagement/BusinessEnquiry/BusinessEnquiry"
        );
        return { Component: BusinessEnquiry };
      },
    },
    {
      path: ROUTES.UI.PROJECT_MANAGEMENT.BUSINESS_ENQUIRY.BUSINESS_ENQUIRY_FORM
        .RELATIVE,
      async lazy() {
        let { BusinessEnquiryForm } = await import(
          "@/pages/ProjectManagement/BusinessEnquiry/BusinessEnquiryForm"
        );
        return { Component: BusinessEnquiryForm };
      },
    },
    {
      path: ROUTES.UI.PROJECT_MANAGEMENT.CONTRACT_REGISTRATION.RELATIVE,
      async lazy() {
        let { ContractRegistration } = await import(
          "@/pages/ProjectManagement/Contracts/ContractRegistration"
        );
        return { Component: ContractRegistration };
      },
    },
    {
      path: ROUTES.UI.PROJECT_MANAGEMENT.CONTRACT_REGISTRATION
        .CONTRACT_REGISTRATION_FORM.RELATIVE,
      async lazy() {
        let { ContractRegistrationForm } = await import(
          "@/pages/ProjectManagement/Contracts/ContractRegistrationForm"
        );
        return { Component: ContractRegistrationForm };
      },
    },
    {
      path: ROUTES.UI.PROJECT_MANAGEMENT.CONTRACT_REGISTRATION
        .CONTRACT_TASK_ORDER_FORM.RELATIVE,
      async lazy() {
        let { TaskOrderForm } = await import(
          "@/pages/ProjectManagement/Contracts/TaskOrderForm"
        );
        return { Component: TaskOrderForm };
      },
    },
    {
      path: ROUTES.UI.PROJECT_MANAGEMENT.CONTRACT_REGISTRATION
      .CONTRACT_INVOICE_RATE_FORM.RELATIVE,
      async lazy() {
        let { InvoiceRateForm } = await import(
          "@/pages/ProjectManagement/Contracts/InvoiceRateForm"
        );
        return { Component: InvoiceRateForm };
      },
    },
    {
      path: ROUTES.UI.PROJECT_MANAGEMENT.CONTRACT_REGISTRATION
      .CONTRACT_CONTRACT_POC_FORM.RELATIVE,
      async lazy() {
        let { ContractPocForm } = await import(
          "@/pages/ProjectManagement/Contracts/ContractPocForm"
        );
        return { Component: ContractPocForm };
      },
    },
    {
      path: ROUTES.UI.PROJECT_MANAGEMENT.PROJECT_EXECUTION.RELATIVE,
      async lazy() {
        let { ProjectExecution } = await import(
          "@/pages/ProjectManagement/ProjectExecution/ProjectExecution"
        );
        return { Component: ProjectExecution };
      },
    },
    {
      path: ROUTES.UI.PROJECT_MANAGEMENT.PROJECT_EXECUTION
        .PROJECT_EXECUTION_FORM.RELATIVE,
      async lazy() {
        let { ProjectExecutionForm } = await import(
          "@/pages/ProjectManagement/ProjectExecution/ProjectExecutionForm"
        );
        return { Component: ProjectExecutionForm };
      },
    },
  ],
};
