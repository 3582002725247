export const API_V1 = "/api";

export const ApiUrlRoutes = {
  GET_ALL_COUNTRIES: "https://countriesnow.space/api/v0.1/countries/iso",
  GET_ALL_STATES_BY_COUNTRY:
    "https://countriesnow.space/api/v0.1/countries/states",

  /// Authentication
  AUTHENTICATION: {
    LOGIN: API_V1 + "/authenticate",
    SIGN_OUT: API_V1 + "/sign-out",
    SIGNUP: API_V1 + "/register-user",
  },

  ASSET: {
    ALL: API_V1 + "/assets",
    GET_BY_ID: (id: any) => ApiUrlRoutes.ASSET.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.ASSET.ALL,
    UPDATE_BY_ID: (id: any) => ApiUrlRoutes.ASSET.ALL + `/${id}`,
    DELETE_BY_ID: (id: any) => ApiUrlRoutes.ASSET.ALL + `/${id}`,
  },

  ATTENDANCE_REGULARIZATION: {
    ALL: API_V1 + "/attendance-regularizations",
    GET_BY_ID: (id: any) =>
      ApiUrlRoutes.ATTENDANCE_REGULARIZATION.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.ATTENDANCE_REGULARIZATION.ALL,
    UPDATE_BY_ID: (id: any) =>
      ApiUrlRoutes.ATTENDANCE_REGULARIZATION.ALL + `/${id}`,
    DELETE_BY_ID: (id: any) =>
      ApiUrlRoutes.ATTENDANCE_REGULARIZATION.ALL + `/${id}`,
  },

  /// Business Enquiry
  BUSINESS_ENQUIRY: {
    ALL: API_V1 + "/businessEnquiry",
    GET_BY_ID: (id: any) => ApiUrlRoutes.BUSINESS_ENQUIRY.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.BUSINESS_ENQUIRY.ALL,
  },

  /// CheckIn Checkout
  CHECK_IN_OUT_EVENTS: {
    ALL: API_V1 + "/check-in-out-events",
    GET_BY_ID: (id: any) => ApiUrlRoutes.CHECK_IN_OUT_EVENTS.ALL + `/${id}`,
    CALENDAR: () => ApiUrlRoutes.CHECK_IN_OUT_EVENTS.ALL + "/calendar",
    GET_BY_EMPLOYEE_ID: (id: any) =>
      ApiUrlRoutes.CHECK_IN_OUT_EVENTS.ALL + `/employee/${id}`,
    GET_BY_MISSING_EVENT_BY_USER_ID: (id: any) =>
      ApiUrlRoutes.CHECK_IN_OUT_EVENTS.ALL + `/missing-events/${id}`,
    GET_BY_OFFICE_HOURS_BY_USER_ID: (id: any) =>
      ApiUrlRoutes.CHECK_IN_OUT_EVENTS.ALL + `/offic-hours/${id}`,
    SAVE: () => ApiUrlRoutes.CHECK_IN_OUT_EVENTS.ALL,
  },

  /// Contracts
  CONTRACT_COMPANIES: {
    ALL: API_V1 + "/contractCompanies",
    GET_BY_ID: (id: any) => ApiUrlRoutes.CONTRACT_COMPANIES.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.CONTRACT_COMPANIES.ALL,
  },

  CONTRACTS: {
    ALL: API_V1 + "/contracts",
    GET_BY_ID: (id: any) => ApiUrlRoutes.CONTRACTS.ALL + `/${id}`,
    GET_BY_COMPANY_ID: (companyId: any) =>
      ApiUrlRoutes.CONTRACTS.ALL + `/company/${companyId}`,
    SAVE: () => ApiUrlRoutes.CONTRACTS.ALL,
  },

  CONTRACT_INVOICES: {
    ALL: API_V1 + "/contractInvoices",
    GET_BY_ID: (id: any) => ApiUrlRoutes.CONTRACT_INVOICES.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.CONTRACT_INVOICES.ALL,
  },

  CONTRACT_POC: {
    ALL: API_V1 + "/contract-poc",
    GET_BY_ID: (id: any) => ApiUrlRoutes.CONTRACT_POC.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.CONTRACT_POC.ALL,
  },

  CONTRACT_TASK_ORDER: {
    ALL: API_V1 + "/contractTaskOrders",
    GET_BY_ID: (id: any) => ApiUrlRoutes.CONTRACT_TASK_ORDER.ALL + `/${id}`,
    GET_BY_CONTRACT_ID: (contractId: any) =>
      ApiUrlRoutes.CONTRACT_TASK_ORDER.ALL + `/contract/${contractId}`,
    SAVE: () => ApiUrlRoutes.CONTRACT_TASK_ORDER.ALL,
  },

  EMPLOYEE_DOCUMENT: {
    ALL: API_V1 + "/employee-documents",
    GET_BY_ID: (id: any) => ApiUrlRoutes.EMPLOYEE_DOCUMENT.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.EMPLOYEE_DOCUMENT.ALL,
    UPDATE_BY_ID: (id: any) => ApiUrlRoutes.EMPLOYEE_DOCUMENT.ALL + `/${id}`,
    DELETE_BY_ID: (id: any) => ApiUrlRoutes.EMPLOYEE_DOCUMENT.ALL + `/${id}`,
  },

  EMPLOYEE_EVENT_BALANCE: {
    ALL: API_V1 + "/employee-event-balance",
    GET_BY_ID: (id: any) => ApiUrlRoutes.EMPLOYEE_EVENT_BALANCE.ALL + `/${id}`,
    GET_BY_EMPLOYEE_ID: (id: any) =>
      ApiUrlRoutes.EMPLOYEE_EVENT_BALANCE.ALL + `/employee/${id}`,
    SAVE: () => ApiUrlRoutes.EMPLOYEE_EVENT_BALANCE.ALL,
  },

  EMPLOYEE_EVENT_REQUEST: {
    ALL: API_V1 + "/employee-event-request",
    GET_BY_ID: (id: any) => ApiUrlRoutes.EMPLOYEE_EVENT_REQUEST.ALL + `/${id}`,
    GET_BY_EMPLOYEE_ID: (id: any) =>
      ApiUrlRoutes.EMPLOYEE_EVENT_REQUEST.ALL + `/employee/${id}`,
    SAVE: () => ApiUrlRoutes.EMPLOYEE_EVENT_REQUEST.ALL,
  },

  EMPLOYEE: {
    ALL: API_V1 + "/employee",
    GET_BY_ID: (id: any) => ApiUrlRoutes.EMPLOYEE.ALL + `/${id}`,
    GET_BY_EMPLOYEE_ID: (id: any) => ApiUrlRoutes.EMPLOYEE.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.EMPLOYEE.ALL,
    CREATE_BASIC_USER_FOR_HR: () => "/employee/hr",
  },

  ESTIMATION_METHOD: {
    ALL: API_V1 + "/estimation-methods",
    GET_BY_ID: (id: any) => ApiUrlRoutes.ESTIMATION_METHOD.ALL + `/${id}`,
    GET_BY_EMPLOYEE_ID: (id: any) =>
      ApiUrlRoutes.ESTIMATION_METHOD.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.ESTIMATION_METHOD.ALL,
  },

  FEEDS: {
    ALL: API_V1 + "/feeds",
    GET_BY_ID: (id: any) => ApiUrlRoutes.FEEDS.ALL + `/${id}`,
    GET_BY_EMPLOYEE_ID: (id: any) => ApiUrlRoutes.FEEDS.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.FEEDS.ALL,
  },

  FILES: {
    DOWNLOAD: (filename: string) => API_V1 + `/files/downloadFile/${filename}`,
    UPLOAD: (categoryId: number) => API_V1 + `/files/uploadFile/${categoryId}`,
  },

  FILE_CATEGORY: {
    ALL: API_V1 + "/file-category",
    GET_BY_ID: (id: any) => ApiUrlRoutes.FILE_CATEGORY.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.FILE_CATEGORY.ALL,
    UPDATE_BY_ID: (id: any) => ApiUrlRoutes.FILE_CATEGORY.ALL + `/${id}`,
    DELETE_BY_ID: (id: any) => ApiUrlRoutes.FILE_CATEGORY.ALL + `/${id}`,
  },

  FLASH: {
    ALL: API_V1 + "/flashes",
    GET_BY_ID: (id: any) => ApiUrlRoutes.FLASH.ALL + `/${id}`,
    GET_BY_EMPLOYEE_ID: (id: any) => ApiUrlRoutes.FLASH.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.FLASH.ALL,
  },

  HELPDESK_TICKET: {
    ALL: API_V1 + "/helpdesk-tickets",
    GET_BY_ID: (id: any) => ApiUrlRoutes.HELPDESK_TICKET.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.HELPDESK_TICKET.ALL,
    UPDATE_BY_ID: (id: any) => ApiUrlRoutes.HELPDESK_TICKET.ALL + `/${id}`,
    DELETE_BY_ID: (id: any) => ApiUrlRoutes.HELPDESK_TICKET.ALL + `/${id}`,
  },

  HOLIDAYS: {
    ALL: API_V1 + "/holidays",
    GET_BY_ID: (id: any) => ApiUrlRoutes.HOLIDAYS.ALL + `/${id}`,
    GET_BY_EMPLOYEE_ID: (id: any) => ApiUrlRoutes.HOLIDAYS.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.HOLIDAYS.ALL,
  },

  IT_POLICY_LINK: {
    ALL: API_V1 + "/it-policies",
    GET_BY_ID: (id: any) => ApiUrlRoutes.IT_POLICY_LINK.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.IT_POLICY_LINK.ALL,
    UPDATE_BY_ID: (id: any) => ApiUrlRoutes.IT_POLICY_LINK.ALL + `/${id}`,
    DELETE_BY_ID: (id: any) => ApiUrlRoutes.IT_POLICY_LINK.ALL + `/${id}`,
  },

  MASTER: {
    GET_MASTER: () => API_V1 + "/master",
    GET_LABEL: () => API_V1 + "/master/label",
  },

  /// Role
  ROLE: {
    ALL: API_V1 + "/roles",
    GET_BY_ID: (id: any) => ApiUrlRoutes.ROLE.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.ROLE.ALL,
  },

  MEDICLAIM_LINK: {
    ALL: API_V1 + "/mediclaim-links",
    GET_BY_ID: (id: any) => ApiUrlRoutes.MEDICLAIM_LINK.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.MEDICLAIM_LINK.ALL,
    UPDATE_BY_ID: (id: any) => ApiUrlRoutes.MEDICLAIM_LINK.ALL + `/${id}`,
    DELETE_BY_ID: (id: any) => ApiUrlRoutes.MEDICLAIM_LINK.ALL + `/${id}`,
  },

  MERGE_EVENT_REQUESTS: {
    GET_BY_EMPLOYEE_ID: (id: any) =>
      API_V1 + `/merge-event-requests/employee/${id}`,
  },

  PROJECT_CATEGORY: {
    ALL: API_V1 + "/project-categories",
    GET_BY_ID: (id: any) => ApiUrlRoutes.PROJECT_CATEGORY.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.PROJECT_CATEGORY.ALL,
  },

  PF_LINK: {
    ALL: API_V1 + "/pf-links",
    GET_BY_ID: (id: any) => ApiUrlRoutes.PF_LINK.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.PF_LINK.ALL,
    UPDATE_BY_ID: (id: any) => ApiUrlRoutes.PF_LINK.ALL + `/${id}`,
    DELETE_BY_ID: (id: any) => ApiUrlRoutes.PF_LINK.ALL + `/${id}`,
  },

  REIMBURSEMENT: {
    ALL: API_V1 + "/reimbursements",
    GET_BY_ID: (id: any) => ApiUrlRoutes.REIMBURSEMENT.ALL + `/${id}`,
    SAVE: () => ApiUrlRoutes.REIMBURSEMENT.ALL,
    UPDATE_BY_ID: (id: any) => ApiUrlRoutes.REIMBURSEMENT.ALL + `/${id}`,
    DELETE_BY_ID: (id: any) => ApiUrlRoutes.REIMBURSEMENT.ALL + `/${id}`,
  },

  USER_CONTEXT: {
    ALL: API_V1 + "/users/context",
  },
};
