import { createSlice } from "@reduxjs/toolkit";

const initialUserContextState = {
  userId: null,
  username: null,
  userContactNumber: null,
  userEmailId: null,
  userIsActive: null,
  userLastModifiedBy: null,
  userCreatedDate: null,
  userLastModifiedDate: null,
};

export const userContextSlice = createSlice({
  name: "userContextSlice",
  initialState: initialUserContextState,
  reducers: {
    // (state, action: PayloadAction<>)
    setUserContext(state, action) {
      state = JSON.parse(JSON.stringify(action.payload));
      return state;
    },
    deleteUserContext(state) {
      state = initialUserContextState;
    },
  },
});

export const userContextActions = userContextSlice.actions;
