import { getData } from "@/api/api.service";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function jsonParse(input: string) {
  if (input === null && input === undefined) return null;

  try {
    return JSON.parse(input);
  } catch (e) {
    console.error("Unable to parse json - ", e);
    return null;
  }
}


export const download = async (url: string) => {
    try {
        const response:any = await getData({
                    endpoint: url,
                    config: {responseType: 'blob'}
                });

        const blob = new Blob([response], { type: response.type });
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        //do not remove below URL
        link.download = url.replace("http://localhost:8080/api/files/downloadFile/", ""); // Set the download attribute with the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
    } catch (error) {
        console.error('Download failed:', error);
    }
};

export function isArrayOfString(value: any): value is string[] {
  return (
    Array.isArray(value) && value.every((item) => typeof item === "string")
  );
}