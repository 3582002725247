import { redirect } from "react-router-dom";
import { ROUTES } from "./routes.constants";

export const helpdeskRoutes = {
  path: ROUTES.UI.IT.RELATIVE,
  async lazy() {
    let { SelfServiceManagement } = await import(
      "@/pages/SelfService/SelfServiceManagement"
    );
    return { Component: SelfServiceManagement };
  },
  children: [
    {
      path: "",
      loader: () => redirect(ROUTES.UI.IT.HELP_DESK.ABSOLUTE),
    },
    {
      path:  ROUTES.UI.IT.HELP_DESK.RELATIVE,
      async lazy() {
        let { HelpdeskTabs } = await import("@/pages/Helpdesk/HelpdeskTabs");
        return { Component: HelpdeskTabs };
      },
    },
    {
      path: ROUTES.UI.IT.HELP_DESK_LIST.RELATIVE,
      async lazy() {
        let { HelpdeskTicketList } = await import(
          "@/pages/Helpdesk/HelpdeskTicketList"
        );
        return { Component: HelpdeskTicketList };
      },
    },
    {
      path: ROUTES.UI.IT.HELP_DESK_REQUEST.RELATIVE,
      async lazy() {
        let { HelpdeskTicketRequestForm } = await import(
          "@/pages/Helpdesk/HelpdeskTicketRequestForm"
        );
        return { Component: HelpdeskTicketRequestForm };
      },
    },
    {
      path: ROUTES.UI.IT.IT_POLICIES_REQUEST.RELATIVE,
      async lazy() {
        const { ITPolicies } = await import("@/pages/Helpdesk/ITPolicies");
        return { Component: ITPolicies };
      },
    },
  ],
};
