import { redirect } from "react-router-dom";
import { ROUTES } from "./routes.constants";

export const humanResourcesRoutes = {
  path: ROUTES.UI.HUMAN_RESOURCES.RELATIVE,
  async lazy() {
    let { HumanResources } = await import(
      "@/pages/HumanResources/HumanResources"
    );
    return { Component: HumanResources };
  },
  children: [
    {
      path: "",
      loader: () => redirect(ROUTES.UI.HUMAN_RESOURCES.ABSOLUTE),
    },
    {
      path: ROUTES.UI.HUMAN_RESOURCES.DASHBOARD.RELATIVE,
      async lazy() {
        let { HumanResourcesDashboard } = await import(
          "@/pages/HumanResources/HumanResourcesDashboard"
        );
        return { Component: HumanResourcesDashboard };
      },
    },
    {
      path: ROUTES.UI.HUMAN_RESOURCES.SELF_PROFILE.RELATIVE,
      async lazy() {
        let { SelfProfile } = await import(
          "@/pages/HumanResources/SelfProfile"
        );
        return { Component: SelfProfile };
      },
    },
    {
      path: ROUTES.UI.HUMAN_RESOURCES.BENEFITS_AND_COMPENSATION.RELATIVE,
      async lazy() {
        let { BenefitsAndCompensationTabs } = await import(
          "@/pages/HumanResources/BenefitsAndCompensation/BenefitsAndCompensationTabs"
        );
        return { Component: BenefitsAndCompensationTabs };
      },
    },
    {
      path: ROUTES.UI.HUMAN_RESOURCES.REIMBURSMENT_REQUEST_FORM.RELATIVE,
      async lazy() {
        let { ReimbursementRequestForm } = await import(
          "@/pages/HumanResources/BenefitsAndCompensation/Reimbursement/ReimbursementRequestForm"
        );
        return { Component: ReimbursementRequestForm };
      },
    },
    {
      path: ROUTES.UI.HUMAN_RESOURCES.REIMBURSMENT_REQUEST_LIST.RELATIVE,
      async lazy() {
        let { ReimbursementList } = await import(
          "@/pages/HumanResources/BenefitsAndCompensation/Reimbursement/ReimbursementList"
        );
        return { Component: ReimbursementList };
      },
    },
    {
      path: ROUTES.UI.HUMAN_RESOURCES.ASSET_LIST.RELATIVE,
      async lazy() {
        let { AssetList } = await import(
          "@/pages/HumanResources/Assets/AssetList"
        );
        return { Component: AssetList };
      },
    },
    {
      path: ROUTES.UI.HUMAN_RESOURCES.ASSET_REQUEST_FORM.RELATIVE,
      async lazy() {
        let { AssetRequestForm } = await import(
          "@/pages/HumanResources/Assets/AssetRequestForm"
        );
        return { Component: AssetRequestForm };
      },
    },
    {
      path: ROUTES.UI.HUMAN_RESOURCES.DOCUMENTS.RELATIVE,
      async lazy() {
        let { DocumentTabs } = await import(
          "@/pages/HumanResources/Documents/DocumentTabs"
        );
        return { Component: DocumentTabs };
      },
    },
    {
      path: ROUTES.UI.HUMAN_RESOURCES.UPLOAD_DOCUMENT_FORM.RELATIVE,
      async lazy() {
        let { UploadDocumentForm } = await import(
          "@/pages/HumanResources/Documents/MyDocuments/UploadDocumentForm"
        );
        return { Component: UploadDocumentForm };
      },
    },
  ],
};
