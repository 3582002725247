import { redirect } from "react-router-dom";
import { ROUTES } from "./routes.constants";

export const selfServiceRoutes = {
  path: ROUTES.UI.SELF_SERVICE.RELATIVE,
  async lazy() {
    let { SelfServiceManagement } = await import(
      "@/pages/SelfService/SelfServiceManagement"
    );
    return { Component: SelfServiceManagement };
  },
  children: [
    {
      path: "",
      loader: () => redirect(ROUTES.UI.SELF_SERVICE.ABSOLUTE),
    },
    {
      path: ROUTES.UI.SELF_SERVICE.TIME_SHEET.RELATIVE,
      async lazy() {
        let { Timesheet } = await import("@/pages/SelfService/Timesheet");
        return { Component: Timesheet };
      },
    },
    {
      path: ROUTES.UI.SELF_SERVICE.LEAVES.RELATIVE,
      async lazy() {
        let { LeavesDashboard } = await import(
          "@/pages/SelfService/Leaves/LeavesDashboard"
        );
        return { Component: LeavesDashboard };
      },
    },
    {
      path: ROUTES.UI.SELF_SERVICE.LEAVES.LEAVES_REQUEST.RELATIVE,
      async lazy() {
        let { LeaveRequestForm } = await import(
          "@/pages/SelfService/Leaves/LeaveRequestForm"
        );
        return { Component: LeaveRequestForm };
      },
    },
    {
      path: "leaves/leave-request-list",
      async lazy() {
        let { LeaveRequestList } = await import(
          "@/pages/SelfService/Leaves/LeaveRequestList"
        );
        return { Component: LeaveRequestList };
      },
    },
    {
      path: ROUTES.UI.SELF_SERVICE.ATTENDANCE.RELATIVE,
      async lazy() {
        let { AttendanceDashboard } = await import(
          "@/pages/SelfService/Attendance/AttendanceDashboard"
        );
        return { Component: AttendanceDashboard };
      },
    },
    {
      path: ROUTES.UI.SELF_SERVICE.ATTENDANCE.ATTENDANCE_WFH_REQUEST.RELATIVE,
      async lazy() {
        let { WorkFromHomeRequestForm } = await import(
          "@/pages/SelfService/Attendance/WorkFromHomeRequestForm"
        );
        return { Component: WorkFromHomeRequestForm };
      },
    },
    {
      path: "attendance/wfh-request-list",
      async lazy() {
        let { WorkFromHomeList } = await import(
          "@/pages/SelfService/Attendance/WorkFromHomeList"
        );
        return { Component: WorkFromHomeList };
      },
    },
    {
      path: ROUTES.UI.SELF_SERVICE.ATTENDANCE.ATTENDANCE_REGULARIZATION_REQUEST
        .RELATIVE,
      async lazy() {
        let { AttendanceRegularizationRequestForm } = await import(
          "@/pages/SelfService/Attendance/AttendanceRegularizationRequestForm"
        );
        return { Component: AttendanceRegularizationRequestForm };
      },
    },
    {
      path: "attendance/attendance-regularization-list",
      async lazy() {
        let { RegularizationList } = await import(
          "@/pages/SelfService/Attendance/RegularizationList"
        );
        return { Component: RegularizationList };
      },
    },
  ],
};
