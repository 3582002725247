import { Button } from "@/components/ui/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { ThemeToggle } from "@/components/layout/ThemeToggle/theme-toggle";
import { Card } from "@/components/ui/card";
import { authProvider } from "@/auth/authProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { COMMON_MESSAGES } from "@/lib/constants";
import { postData } from "@/api/api.service";
import { ApiUrlRoutes } from "@/api/api.constants";
import { ROUTES } from "@/router/routes.constants";
import { useState } from "react";
import { LoaderCircle } from "lucide-react";

const formSchema = z.object({
  username: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  password: z.string(),
});

function Login() {
  const navigate = useNavigate();
  let location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      const responseData: AUTHENTICATE_RESPONSE = await postData({
        endpoint: ApiUrlRoutes.AUTHENTICATION.LOGIN,
        data: {
          username: values.username,
          password: values.password,
        },
        handleLoaders: (loaderState: boolean) => {
          if (loaderState) {
            setLoading(true);
          } else {
            setLoading(false);
          }
        },
      });
      if (responseData?.token) {
        toast.success(COMMON_MESSAGES.LOG_IN);
        authProvider.signin(responseData?.token);
        let redirectTo = new URLSearchParams(location.search)?.get(
          "redirectTo"
        );
        navigate(redirectTo || ROUTES.UI.DASHBOARD.ABSOLUTE);
      } else {
        toast.error(COMMON_MESSAGES.INTERNAL_SERVER_ERROR);
      }
    } catch (error) {}
  }

  return (
    <>
      <div className="bg-muted">
        <div className="fixed top-10 right-10">
          <ThemeToggle />
        </div>
        <div className="h-screen flex flex-col justify-center items-center space-y-6">
          <h1 className="text-3xl">Lektus</h1>
          <Card className="space-y-6 px-12 py-10">
            <div className="text-xl">
              Login with your Username and Password.
            </div>
            <div>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-6"
                >
                  <FormField
                    control={form.control}
                    name="username"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Username</FormLabel>

                        <FormControl>
                          <Input placeholder="johndoe" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="******"
                            {...field}
                            type={"password"}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="text-center">
                    <Button
                      className="font-bold px-10"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && (
                        <LoaderCircle size={20} className="animate-spin" />
                      )}
                      Login
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Login;
