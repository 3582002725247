import {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  CancelToken,
} from "axios";
import { apiClient } from "./api.interceptor";

export type apiRequestProps = {
  endpoint: string;
  timeout?: number;
  cancelToken?: CancelToken;
  headers?: AxiosRequestHeaders;
  config?: AxiosRequestConfig;
  handleLoaders?: React.Dispatch<boolean>;
};

export interface postDataRequestProps extends apiRequestProps {
  data: any;
}
export interface putRequestDataProps extends apiRequestProps {
  data: any;
}

export const getData = async <T>(props: apiRequestProps): Promise<T> => {
  try {
    let config: AxiosRequestConfig = {
      cancelToken: props?.cancelToken,
      headers: props?.headers,
      timeout: props?.timeout,
      ...props?.config
    };
    if (props?.handleLoaders) {
      props?.handleLoaders(true);
    }
    const response: AxiosResponse<T> = await apiClient.get(
      props.endpoint,
      config
    );
    if (props?.handleLoaders) {
      props?.handleLoaders(false);
    }
    return response.data;
  } catch (error) {
    if (props?.handleLoaders) {
      props?.handleLoaders(false);
    }
    console.error("Error fetching data:", error);
    throw error; // Throw the error for handling in components
  }
};

export const postData = async <T>({
  endpoint,
  data,
  ...props
}: postDataRequestProps): Promise<T> => {
  try {
    let config: AxiosRequestConfig = {
      cancelToken: props?.cancelToken,
      headers: props?.headers,
      timeout: props?.timeout,
      ...props?.config
    };
    if (props?.handleLoaders) {
      props?.handleLoaders(true);
    }
    const response: AxiosResponse<T> = await apiClient.post(
      endpoint,
      data,
      config
    );
    if (props?.handleLoaders) {
      props?.handleLoaders(false);
    }
    return response.data;
  } catch (error) {
    if (props?.handleLoaders) {
      props?.handleLoaders(false);
    }
    console.error("Error posting data:", error);
    throw error; // Throw the error for handling in components
  }
};

export const putData = async <T>({
  endpoint,
  data,
  ...props
}: putRequestDataProps): Promise<T> => {
  try {
    let config: AxiosRequestConfig = {
      cancelToken: props?.cancelToken,
      headers: props?.headers,
      timeout: props?.timeout,
    };
    if (props?.handleLoaders) {
      props?.handleLoaders(true);
    }
    const response: AxiosResponse<T> = await apiClient.put(
      endpoint,
      data,
      config
    );
    if (props?.handleLoaders) {
      props?.handleLoaders(false);
    }
    return response.data;
  } catch (error) {
    if (props?.handleLoaders) {
      props?.handleLoaders(false);
    }
    console.error("Error updating data:", error);
    throw error; // Throw the error for handling in components
  }
};

export const deleteData = async <T>({
  endpoint,
  ...props
}: apiRequestProps): Promise<T> => {
  try {
    let config: AxiosRequestConfig = {
      cancelToken: props?.cancelToken,
      headers: props?.headers,
      timeout: props?.timeout,
    };
    if (props?.handleLoaders) {
      props?.handleLoaders(true);
    }
    const response: AxiosResponse<T> = await apiClient.delete(endpoint, config);
    if (props?.handleLoaders) {
      props?.handleLoaders(false);
    }
    return response.data;
  } catch (error) {
    if (props?.handleLoaders) {
      props?.handleLoaders(false);
    }
    console.error("Error deleting data:", error);
    throw error; // Throw the error for handling in components
  }
};

